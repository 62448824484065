.roundedButton{
    border-radius: 3em;
    border: 1px solid #fff;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    
    color: #fff;
    width: 148px;
   


    p{
        position: relative;
        z-index: 10;
        transition: color 0.4s linear; 
    }

    &:hover{
        p{
            color: #000;
        }
    }
}

.roundedButton:hover{
    color: #000; 
}
.circle{
    width: 100%;
    height: 150%;
    position: absolute;
    border-radius: 50%;
    top: 100%;
    z-index: -1;
}

@media (max-width: 991.98px) { 

    .roundedButton{
       
        width: 120px;

 }
}

@media (max-width: 600px) { 

    .roundedButton{
       font-size: 14px;
        width: 100px;

 }
}
